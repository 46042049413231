import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="overview">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Oopsie... Not Found <span role="img" aria-label="">🤯</span></h1>
            <p>Looks like you've followed a broken link or entered a URL that doesn't exist on this site.</p>
            <Link to='/' className="button button--red button--rounded button--large">&laquo; Back to our site</Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
